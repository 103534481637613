import { login, getInfo } from '@/api/user'
import { getToken, setToken, removeToken } from '@/utils/auth'

const getDefaultState = () => {
  return {
    token: getToken(),
    name: '',
    avatar: '',
    sex: '',
    job_num: '',
    job_title: '',
    education: '',
    user_id: '',
    roles: []
  }
}

const state = getDefaultState()

const mutations = {
  RESET_STATE: (state) => {
    Object.assign(state, getDefaultState())
  },
  SET_TOKEN: (state, token) => {
    state.token = token
  },
  SET_NAME: (state, name) => {
    state.name = name
  },
  SET_AVATAR: (state, avatar) => {
    state.avatar = avatar
  },
  SET_ROLES: (state, roles) => {
    state.roles = roles
  },
  SET_SEX: (state, data) => {
    state.sex = data
  },
  SET_JOBNUM: (state, data) => {
    state.job_num = data
  },
  SET_JOBTITLE: (state, data) => {
    state.job_title = data
  },
  SET_EDUCATION: (state, data) => {
    state.education = data
  },
  SET_USERID: (state, data) => {
    state.user_id = data
  }
}

const actions = {
  // user login
  login({ commit }, userInfo) {
    const { username, password, sms_code, type } = userInfo
    return new Promise((resolve, reject) => {
      login({ username: username.trim(), password: password, sms_code: sms_code, login_type: type*1 }).then(response => {
        const { data } = response
        commit('SET_TOKEN', data.token)
        setToken(data.token)
        resolve()
      }).catch(error => {
        reject(error)
      })
    })
  },

  // get user info
  getInfo({ commit, state }) {
    return new Promise((resolve, reject) => {
      getInfo().then(response => {
        const { data } = response

        if (!data) {
          reject('Verification failed, please Login again.')
        }

        const { roles, name, avatar, job_num, job_title, sex, education, user_id } = data

        // roles must be a non-empty array
        if (!roles || roles.length <= 0) {
          reject('getInfo: roles must be a non-null array!')
        }
        commit('SET_ROLES', roles)
        commit('SET_NAME', name)
        commit('SET_AVATAR', avatar)
        commit('SET_JOBTITLE', job_title)
        commit('SET_JOBNUM', job_num)
        commit('SET_SEX', sex)
        commit('SET_EDUCATION', education)
        commit('SET_USERID', user_id)
        resolve(data)
      }).catch(error => {
        reject(error)
      })
    })
  },

  // user logout
  logout({ commit, state }) {
    removeToken() // must remove  token  first
    commit('RESET_STATE')
    // return new Promise((resolve, reject) => {
    //   logout(state.token).then(() => {
    //     removeToken() // must remove  token  first
    //     resetRouter()
    //     commit('RESET_STATE')
    //     resolve()
    //   }).catch(error => {
    //     reject(error)
    //   })
    // })
  },

  // remove token
  resetToken({ commit }) {
    return new Promise(resolve => {
      removeToken() // must remove  token  first
      commit('RESET_STATE')
      resolve()
    })
  },

  // 设置userInfo
  setUserInfo({ commit }, userData) {
    commit('SET_ROLES', userData.roles)
    commit('SET_NAME', userData.name)
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}

