import request from '@/utils/request'

// 登录
export function login(data) {
  return request({
    url: '/login',
    method: 'post',
    data
  })
}
// 获得个人信息
export function getInfo() {
  return request({
    url: '/user/info',
    method: 'get'
  })
}
// 注册
export function register(data) {
  return request({
    url: '/register',
    method: 'post',
    data
  })
}

// 新登录
export function login_new(data) {
  return request({
    url: '/api/login/',
    method: 'post',
    data
  })
}
// 新注册
export function register_new(data) {
  return request({
    url: '/api/register/',
    method: 'post',
    data
  })
}
// 重置密码
export function changePasswd(data) {
  return request({
    url: '/api/change_passwd/',
    method: 'post',
    data
  })
}
// 获取短信验证码
export function sendSmsCode(data) {
  return request({
    url: '/api/send_sms_code/',
    method: 'post',
    data
  })
}
